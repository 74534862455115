<template>
  <div class="productDetails">
    <Breadcrumb title="My Cart"/>

    <div class="product-background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-center mt-5">My Profile</h3>
          </div>
        </div>
      </div>
  
      <div class="container">
        <div class="product-info">
          <div class="row">
              <div class="col-md-6 mx-auto">
                <div class="card" >
                <img class="card-img-top" style="" src="/img/profile.svg" alt="Card image">
                <div class="card-body">
                    <h4 class="card-title text-center">{{ user.name }}</h4>
                    <p style="font-size: 13px; margin-top: -10px" class="card-text text-center mb-5">{{ user.email }}</p>
                    <div class="mt-5 mb-5"></div>
                    <router-view></router-view>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/BreadcrumbComponent";
import { mapGetters } from 'vuex'
export default {
  props: ["title"],
  components: { Breadcrumb },
  data() {
    return {
      img: "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      quantity: 0,
      order_history: ""
    };
  },

  computed: {
    cartTotal() {
      var sum = 0;
      for (var i = 0; i < this.$store.state.cart.length; i++) {
        var currentItem = this.$store.state.cart[i];
        sum += currentItem.product.price * currentItem.count;
      }
      return sum;
    },
    ...mapGetters({
      user: 'user/GET_USER'
    })
  },
  methods: {
    order() {
      let loginInfo = localStorage.getItem("userToken");
      let user_id = localStorage.getItem("fk_uses_id");
      if (loginInfo === null) {
        this.$router.push({ name: "signIn" });
      }
      else if( user_id === null)
      {
         this.$router.push({ name: "signIn" });
      }
      else {
        let cart = JSON.stringify(this.allProduct);
        this.axios.post("/save-order", { cart : cart , fk_users_id : localStorage.getItem("fk_uses_id")})
       .then(() => {
          this.$store.commit('addTCart',0);
          this.$store.commit("cartValueClear", 0);
          localStorage.removeItem("cart");
          this.$router.push({ name: "home" });
          this.$notify({
            group: 'msg',
            type: 'success',
            title: 'Success',
            message: 'Your Order Accepted!',
          });
          this.$router.push({ name: "cart.alert" });
        });
      }
    },
    OrderHistory() {
      // let order_id = localStorage.getItem("fk_uses_id");
      let order_id=2
      if(order_id === null){
        return false;
      }
      else{ 
      this.axios.get("/order-detail?order_id=" + order_id).then(response => {
        this.$notify({
          group: 'msg',
          type: 'success',
          title: 'Success',
          message: 'Your Order History!',
        });
        this.order_history = response.data.orderDetail;
      });
      }
    },

    removeProductItem(index) {
      this.$store.commit("cartItemRemove", index);
      localStorage.removeItem("cart");
      localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.getters.cart_Info)
      );
    },

    productItemIncrease(index, Prouctcount) {
      if (Prouctcount <= 0) {
        // if(confirm("are you want to delete the product ?")){
        this.removeProductItem(index);
        // }
      }
    }
  },
  mounted() {
    this.$store.dispatch('user/ACT_USER')
  }
};
</script>

<style>
.breadcrumb-area:before{
  background: none !important;
  margin-top: -100px;
}
.breadcrumb-area::after{
  background: none !important;
}
.btn {
  border-radius: 16px;
  font-size: 14px;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 70px;
}

.fa-plus {
  transform: rotate(45deg);
}
.action {
  cursor: pointer;
}

.product-info {
  background-color: white;
  /* margin-top: 45px; */
  padding: 0 50px;
}
.breadcrumb-area:before{
  margin-top: 0 !important;
}
.card-img-top {
    display: block;
    text-align: center;
    width: 44%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
    margin: auto;
}
</style>
